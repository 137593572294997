export default {
    namespaced: true,
  
    state: () => ({
      roasters: []
    }),
  
    getters: {
      roasters(state){
        return state.roasters
      }
    },
  
    mutations: {
      populateRoaster(state, newRoaster){
        state.roasters = newRoaster;
      },

      saveRoaster(state,newRoaster)
      {
        state.roasters.push(newRoaster);
      },
      updateRoaster(state,newRoaster)
      {
       
      },
      swapRoaster(state,newRoaster)
      {
       
      },
      importRoaster(state,newRoaster)
      {
       
      }
    },
  
    actions: {
      async getRoasters({ commit }){
        return new Promise((resolve, reject) => {
          axios.get("roaster")
          .then(response => {
            console.log("Roaster Data::: ",response.data.data)
            resolve(response);
            commit("populateRoaster",response.data.data);
          })
          .catch(error => {
            reject(error)
                console.log(error)
            })
          });
      },
      async getDateRange({commit}, apiArg){
        let isSuccess = false
        await axios.post("roaster_date_range",apiArg)
          .then(response => {
            isSuccess = response.data.data
            console.log(response)
            return isSuccess
            })
            .catch(error => {
                isSuccess = false;
                console.log(error)
            })
            return isSuccess;
        },
      async AddRoaster({ commit },apiArg){
        return new Promise((resolve, reject) => {
          axios.post("roaster",apiArg)
          .then(res => {
            console.log("Roaster Data::: ",res.data.data)
            resolve(res);
            commit("saveRoaster",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      async RoasterReport(ctx, data){
        let isSuccess = false
        await  axios.post('get_roster_report', data).then(response => {
          isSuccess = response.data
          return isSuccess
        })
          .catch(error => {
              isSuccess = false;
              console.log(error)
          })
          return isSuccess;
      },
      async swapRoaster({ commit },apiArg){
        return new Promise((resolve, reject) => {
          axios.post("roaster_swap",apiArg)
          .then(res => {
            console.log("Roaster Data::: ",res.data.data)
            resolve(res);
            commit("swapRoaster",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      async importRoasterFile({ commit },apiArg){
        return new Promise((resolve, reject) => {
          axios.post("roaster_import",apiArg)
          .then(res => {
            console.log("Roaster Data::: ",res.data)
            resolve(res);
            commit("importRoaster",res.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      async UpdateRoaster({ commit },apiArg){
        return new Promise((resolve, reject) => {
          axios.put("roaster/"+apiArg.id,apiArg)
          .then(res => {
            console.log("Roaster Data::: ",res.data.data)
            resolve(res);
            commit("updateRoaster",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      async Roasterdetails(ctx,apiArg){
        let isSuccess = false
        await axios.get("roaster/"+apiArg+"/edit").then(response => {
          isSuccess = response.data.data
          console.log(response)
          return isSuccess
        })
          .catch(error => {
              isSuccess = false;
              console.log(error)
          })
          return isSuccess;
        },
    }
  };