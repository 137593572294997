<template>
  <div class="container-fluid">
    <div class="container-wrappero">
      <div class="content-page-header mb-4">
        <div class="breadcrumb-header">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb content_shadow">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Add Company Settings
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- content-page-header end -->
      <v-alert class="mt-4" dense text type="success" dismissible v-if="fixedSuccess">
                        {{fixedSuccess}}
            </v-alert>
            <v-alert class="mt-4" dense text type="error" dismissible v-if="fixedError">
                        {{fixedError}}
            </v-alert>
      <div class="content-body mb-4">
        <v-form
          @submit="saveCompanySetting"
          v-model="valid"
          ref="companysettingform"
          lazy-validation
        >
          <div class="add-contact row">
            <div class="col-md-6">
              <div class="accordion accordion-main" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Company Information
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div class="row g-3">
                        <div class="col-md-12 padding mt-0">
                          <div
                            class="company-image img-thumbnail text-center mb-3"
                          >
                            <img
                              v-if="emp_image"
                              class="img-thumbnail"
                              :src="emp_image"
                              alt=""
                            />
                          </div>
                          <div class="custom-group">
                            <label for="inputEmail4" class="form-label"
                              >Company Logo *</label
                            >
                            <v-file-input
                              accept="image/*"
                              label="File input"
                              outlined
                              solo
                              flat
                              @change="previewImage"
                              v-model="main_image"
                              dense
                            ></v-file-input>
                          </div>
                        </div>
                        <div class="col-md-12 padding mt-2">
                          <div class="custom-group">
                            <label class="form-label">Company Name *</label>
                            <v-text-field
                              placeholder="Enter company name"
                              type="text"
                              v-model="company.name"
                              :rules="requiredRules"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="accordion accordion-main mb-3" id="accordionCompany">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingCompany">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      Details
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingCompany"
                    data-bs-parent="#accordionCompany"
                  >
                    <div class="accordion-body">
                      <div class="row g-3">
                        <div class="col-md-12 padding">
                          <div class="custom-group mt-1">
                            <label class="form-label"
                              >Company's Weblink</label
                            >
                            <v-text-field
                              placeholder="Enter company website link"
                              type="text"
                              v-model="company.web_site_link"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-12 padding mt-0">
                          <div class="custom-group">
                            <label class="form-label">Email *</label>
                            <v-text-field
                              placeholder="Email"
                              type="email"
                              v-model="company.email"
                              :rules="emailRules"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-12 padding mt-0">
                          <div class="custom-group">
                            <label class="form-label">Phone *</label>
                            <v-text-field
                              placeholder="Phone"
                              type="email"
                              v-model="company.phone"
                              :rules="phoneRules"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-12 padding mt-0">
                          <div class="custom-group">
                            <label class="form-label">Address</label>
                            <v-text-field
                              placeholder="Company Address"
                              type="email"
                              v-model="company.address"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-12 padding mt-0">
                          <div class="custom-group">
                            <label for="inputEmail4" class="form-label"
                              >Status</label
                            >
                            <v-select
                              :items="status_items"
                              v-model="company.status"
                              :item-text="(item) => item.name"
                              :item-value="(item) => item.value"
                              outlined
                              dense
                            ></v-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="submit-section">
              <div class="">
                <v-btn
                  class="mr-4 custom-btn"
                  type="button"
                  @click="resetButton()"
                  color="error"
                >
                  Reset
                </v-btn>
              </div>
              <div class="">
                <v-btn
                  class="custom-btn f-right"
                  color="blue darken-4"
                  type="submit"
                  :loading="loading"
                  dark
                >
                  Submit
                </v-btn>
              </div>
            </div>
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>
    
    <script>
import { mapState, mapActions } from "vuex";
export default {
  name: "AddCompanySetting",

  data: () => ({
    company: {
      id: "",
      name: "",
      web_site_link: "",
      email: "",
      phone: "",
      address: "",
      image: "",
      status: 1,
    },
    main_image: [],
    emp_image: null,
    loading: false,
    valid: true,
    fixedSuccess: false,
    fixedError: false,
    error: "",
    previewImageValidation: "",
    status_items: [
      { id: 0, name: "Active", value: 1 },
      { id: 1, name: "De-active", value: 0 },
    ],
    requiredRules: [(v) => !!v || "Field is required"],
    emailRules: [
      (v) => !!v || "Email is required",
      (v) =>
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "Enter valid email address",
    ],
    phoneRules: [
                (v) => !!v || "Phone is required",
                (v) => v.length >= 8 || "Phone number requires at least 8 digit",
                (v) => v.length < 12 || "Phone number cannot more than 11 digit",
            ],
  }),
  async created() {
    this.getCompanySettingsDetails();
  },
  computed: {

  },
  methods: {
    saveCompanySetting(event) {
      event.preventDefault();
      this.loading = true;
      let isValidate = true;
      this.error = "";
      this.$refs.companysettingform.validate();
      this.$refs.companysettingform.inputs.forEach((input) => {
        if (input.hasError) {
          isValidate = false;
        }
      });
      if (!isValidate) {
        this.loading = false;
        return;
      }
      if (this.company.id == "") {
        this.$store
          .dispatch("company_settings/AddCompanySetting", this.company)
          .then((response) => {
            this.loading = false
            if(response.data.data){
              this.fixedSuccess = response.data.message
            }
            else{
              this.fixedError = response.data.message
            }
              this.getCompanySettingsDetails();
            })
          .catch(() => {});
      } else {
        this.$store
          .dispatch("company_settings/UpdateCompanySetting", this.company)
          .then((response) => {
            this.loading = false
            if(response.data.data){
              this.fixedSuccess = response.data.message
            }
            else{
              this.fixedError = response.data.message
            }
            this.getCompanySettingsDetails();
          })
          .catch(() => {});
      }
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    createImage(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.emp_image = e.target.result;
        this.company.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    previewImage(file) {
      if (!file) {
        this.emp_image = null;
        return;
      }
      this.createImage(file);
    },
    getCompanySettingsDetails() {
      this.$store
        .dispatch(
          "company_settings/CompanySettingsDetails",
          this.$route.params.id
        )
        .then((res) => {
          if (res) {
            (this.company.id = res.id),
              (this.company.name = res.name),
              (this.company.web_site_link = res.web_site_link),
              (this.company.email = res.email),
              (this.company.phone = res.phone),
              (this.company.address = res.address),
              (this.emp_image = res.logo),
              (this.company.status = res.status);
          }
        });
    },

    resetButton() {
      this.$refs.companysettingform.reset();
    },
  },
};
</script>
    <style>
.accordion-main {
  border: 1px solid #ddd;
  margin-right: 2px;
  padding: 10px;
}
.add-contact .accordion-item {
  margin-bottom: 0px;
}
.company-image {
  width: 200px;
  height: 200px;
  text-align: center;
  margin: 0 auto;
}
.company-image img {
  height: 100%;
  object-fit: cover;
}
.submit-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
    