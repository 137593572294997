export default {
    namespaced: true,
  
    state: () => ({
        attendances: [],
        employeeforattendances: [],
        holidays: [],
        attendancebyid: [],
    }),
  
    getters: {
        attendances(state){
            return state.attendances
      },
      employeeforattendances(state){
        return state.employeeforattendances
      },
      holidays(state){
        return state.holidays
      },
      attendancebyid(state){
        return state.attendancebyid
      },
    },
  
    mutations: {
      populateAttendance(state, newAttendance){
        state.attendances = newAttendance;
      },
      saveAttendance(state,newAttendance)
      {
        state.attendances.push(newAttendance);
      },
      postAttendance(){

      },
      populateEmployeeforAttendance(state, newEmployeeforAttendance){
        state.employeeforattendances = newEmployeeforAttendance;
      },
      populateHoliday(state, newHoliday){
        state.holidays = newHoliday;
      },
      populateattendancebyid(state, newattendancebyid){
        state.attendancebyid = newattendancebyid;
      },
    },
  
    actions: {
      

      async AttendencReport(ctx, data){
        let isSuccess = false
        await  axios.post('get_attendence_report', data).then(response => {
          isSuccess = response.data
          console.log("sadkfjhskhfkhsdfkjh",response)
          return isSuccess
        })
          .catch(error => {
              isSuccess = false;
              console.log(error)
          })
          return isSuccess;
      },

      async CheckData(ctx, data){
        let isSuccess = false
        await  axios.post('check_device_data', data).then(response => {
          
          isSuccess = response.data
          // console.log("-------------",response)
          return isSuccess
        })
          .catch(error => {
              isSuccess = false;
              // console.log(error)
          })
          return isSuccess;
      },

      async downloadLog(ctx, data){
        let isSuccess = false
        await  axios.post('download_device_data', data).then(response => {
          isSuccess = response.data
          // console.log("-------------",response)
          return isSuccess
        })
          .catch(error => {
              isSuccess = false;
              // console.log(error)
          })
          return isSuccess;
      },

      async processLog(ctx, data){
        let isSuccess = false
        await  axios.post('process_att_data', data).then(response => {
          isSuccess = response.data
          // console.log("-------------",response)
          return isSuccess
        })
          .catch(error => {
              isSuccess = false;
              // console.log(error)
          })
          return isSuccess;
      },

    }
  };