<template>
    <div class="container-wrappero">
        <div class="content-page-header mb-4">
            <div class="breadcrumb-header">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb content_shadow">
                        <li class="breadcrumb-item">
                            <router-link to="/dashboard">Dashboard</router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Download</li>
                    </ol>
                </nav>
            </div>
        </div>
        <v-alert class="mt-4 p-3" dense text type="success" dismissible v-if="fixedSuccess != ''">
            {{fixedSuccess}}
        </v-alert>
        <v-alert class="mt-4 p-3" dense text type="error" dismissible v-if="fixedError != ''">
            {{fixedError}}
        </v-alert>
        
        <div class="content-body  mb-4">
            <v-form class="g-3" ref="salaryreportform" @submit="getDeviceData" v-model="valid" lazy-validation>
                <div class="add-contact">
                    <div class="row">
                        <div class="col-md-3 col-lg-3">
                            <div class="form-group">
                                <label for="" class="mb-2"> From Date
                                </label>
                                <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :rules="fromDateRules" v-model="fromData.from_date" label="Select from date" readonly v-bind="attrs" prepend-inner-icon="mdi-calendar" prepend-icon="" ref="fromdate" @click:prepend-inner="$refs.fromdate.$el.querySelector('input').click()" v-on="on" solo flat outlined dense></v-text-field>
                                    </template>
                                    <v-date-picker v-model="fromData.from_date" @input="menu2 = false"></v-date-picker>
                                </v-menu>
                            </div>
                        </div>
                        <div class="col-md-3 col-lg-3">
                            <div class="form-group">
                                <label for="" class="mb-2"> To Date
                                </label>
                                <v-menu v-model="menu3" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :rules="toDateRules" v-model="fromData.to_date" label="Select to date" readonly v-bind="attrs" prepend-inner-icon="mdi-calendar" prepend-icon="" ref="todate" @click:prepend-inner="$refs.todate.$el.querySelector('input').click()" v-on="on" solo flat outlined dense></v-text-field>
                                    </template>
                                    <v-date-picker v-model="fromData.to_date" @input="menu3 = false"></v-date-picker>
                                </v-menu>
                            </div>
                        </div>
                        <div class="col-md-3 col-lg-3">
                            <div class="form-group">
                                <label for="" class="mb-2"> Employee List
                                </label>
                                <v-autocomplete :items="finalEmployees" v-model="fromData.employee_id" :item-text="item=>item.name+'-'+item.employee_id" :rules="employeeRules" :item-value="item=>item.employee_id" label="Select Employee" solo flat outlined dense></v-autocomplete>
                            </div>
                        </div>

                        <div class="col-md-2">
                            <v-btn :loading="loading" type="submit" class="custom-btn btn-sm btn text-white mr-2">Check</v-btn>
                        </div>
                        
                    </div>
                </div>
            </v-form>
            <div class="row m-2">
                <!-- Device Log -->
                <div class="float-left text-left table-striped card mt-4 w-50" v-if="deviceLog">
                    <div class="row">
                        <div class="float-left text-left pt-2">
                            <h4>Device Log ({{ deviceLog.length }})</h4>
                        </div>
                        <div class="float-right text-right">
                            <v-btn :loading="downloadLoading" @click="dataDownload()" :disabled="downDisabled" class="custom-btn btn-sm btn text-white mb-3 mr-3"><span class="mr-2"><i class="fa fa-download"></i></span> Download from device</v-btn>
                        </div>
                    </div>
                    <div class="table-header" v-bind:style="{height:'500px',overflow:'scroll'}" id="print">
                        <table class="material-table mt-4 table" > 
                            <thead v-bind:style="{position:'sticky'}">
                                <tr class="text-center">
                                    <th>Sl</th>
                                    <th>Employee Id</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody v-if="deviceLog.length > 0">
                                <tr class="text-center" v-for="(deviceLog,index) in deviceLog" :key="index">
                                    <td> {{index + 1}} </td>
                                    <td> JCHL{{deviceLog.PIN }}</td>
                                    <td> {{deviceLog.DateTime }}</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr class="text-center align-middle" :style="{height:'500px'}">
                                    <td colspan="5" class="fs-4 text-uppercase">
                                        No Device Log Available
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="float-right text-right table-striped card mt-4 w-50" v-if="processLog">
                    <div class="row">
                        <div class="float-left text-left pt-2">
                            <h4 v-if="processAlert != ''">{{ processAlert }}</h4>
                            <h4 v-else>Download Log ({{ processLog.length }})</h4>
                        </div>
                        <div class="float-right text-right">
                            <v-btn :loading="processLoading" @click="dataProcess()" :disabled="processDisabled" class="custom-btn btn-sm btn text-white mb-3 mr-3"><span class="mr-2"><i class="fa fa-download"></i></span> Processing</v-btn>
                        </div>
                    </div>
                    <div class="table-header" v-bind:style="{height:'500px',overflow:'scroll'}" id="print">
                        <table class="material-table mt-4 table"> 
                            <thead>
                                <tr class="text-center">
                                    <th>Sl</th>
                                    <th>Employee Id</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody v-if="processLog.length > 0">
                                <tr class="text-center" v-for="(processLog,index) in processLog" :key="index">
                                    <td> {{index + 1}} </td>
                                    <td> {{processLog.employee_id }}</td>
                                    <td> {{processLog.date }}</td>
                                    <td> {{processLog.punch_time }}</td>
                                    <td v-if="processLog.status == 'Ok'" class="bg-success text-white"> {{ processLog.status }}</td>
                                    <td v-if="processLog.status == 'Not Ok'" class="bg-danger text-white"> {{ processLog.status }}</td>
                                    <td v-if="processLog.status == 'Already Exist'" class="bg-warning text-dark"> {{ processLog.status }}</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr class="text-center align-middle" :style="{height:'500px'}">
                                    <td colspan="5" class="fs-4 text-uppercase">
                                        No Processing Log Available
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import DataTable from "vue-materialize-datatable";
import moment from 'moment';
import exportFromJSON from "export-from-json";
import { isObject } from "@vue/shared";
export default {
    name: 'device_download',
    data: () => ({

        // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        // menu: false,
        // modal: false,
        menu2: false,
        menu3: false,
        fromData: {
            from_date: null,
            to_date: null,
            employee_id: null,
        },

        date: null,
        temp_attrs: [],
        deviceLog: [],

        downloadLoading: false,
        downDisabled: true,

        processLog: [],
        processLoading: false,
        processDisabled: true,
        processAlert: "",

        
        loading: false,
        isValidate: false,

        fixedSuccess: "",
        fixedError: "",


        fromDateRules: [
            (v) => !!v || "From date is required",
        ],
        toDateRules: [
            (v) => !!v || "To date is required",
        ],
        employeeRules: [
            (v) => !!v || "Select Employee",
        ],
    }),
    async created() {
        this.getEmployees();
        this.todayReport();
    },

    computed: {
        ...mapState('employee', ['employees']),
        finalEmployees() {
            var employees = this.employees
            employees.unshift({
                employee_id: 'all',
                name: 'All'
            })
            return employees
        }
    },
    
    methods: {
        ...mapActions('employee', ['getEmployees']),
        todayReport() {
            this.fromData.employee_id = 'all';
            this.fromData.from_date = moment(new Date()).format("YYYY-MM-DD")
            this.fromData.to_date = moment(new Date()).format("YYYY-MM-DD")
        },
        
        getDeviceData(event) {
            event.preventDefault();
            this.loading = true
            this.downDisabled = true;
            this.processDisabled = true;
            let isValidate = true
            this.error = "";
            this.$refs.salaryreportform.validate();
            this.$refs.salaryreportform.inputs.forEach((input) => {
                if (input.hasError) {
                    isValidate = false;
                }
            });
            if (!isValidate) {
                this.loading = false
                return;
            };

            this.processAlert = "";
            
            console.log(this.fromData);
            this.submitAttendance();

        },
        submitAttendance(number = null) {
            this.$store.dispatch("device/CheckData", this.fromData).then((response) => {
                console.log("------Console Test-------", response);
                if (response.data) {
                    console.log("-------------",response.data.Row);
                    if(Array.isArray(response.data.Row)){
                        console.log("------Is Array------",response.data.Row);
                        this.deviceLog = response.data.Row;
                    }else{
                        console.log("------Not Array------",response.data.Row);
                        this.deviceLog = [response.data.Row];
                    }
                    this.fixedSuccess = "Log Successfuly Check Count - ("+this.deviceLog.length+")";
                    if(this.deviceLog.length > 0){
                        this.downDisabled = false;
                    }else{
                        this.downDisabled = true;
                    }
                    this.loading = false;
                } else {
                    this.fixedError = "Data not found ("+this.fromData.employee_id+")";
                    this.loading = false;
                }

            }).catch(() => {
                this.loading = false;
            })
            
        },

        dataDownload(){
            this.processAlert = "";
            this.downloadLoading = true;
            if(this.deviceLog.length > 0){
                var jsonDeviceLog = {};
                jsonDeviceLog['save_log'] = JSON.stringify(this.deviceLog);
                this.$store.dispatch("device/downloadLog", jsonDeviceLog).then((response) => {
                    console.log("------Console Test-------", response.data);
                    if (response.data) {
                        this.processLog = response.data;
                        this.fixedSuccess = "Log Successfuly Download Count - ("+this.processLog.length+")";
                        this.downloadLoading = false;
                        if(this.processLog.length > 0){
                            this.processDisabled = false;
                        }else{
                            this.processDisabled = true;
                        }
                    } else {
                        this.fixedError = "Data not found ("+this.fromData.employee_id+")";
                        this.downloadLoading = false;
                    }

                }).catch(() => {
                    this.downloadLoading = false;
                })
                
            }else{
                this.fixedError = "Device log not found";
            }
        },

        dataProcess(){
            this.processAlert = "";
            this.processLoading = true;
            this.processDisabled = true;
            let isValidate = true
            this.error = "";
            this.$refs.salaryreportform.validate();
            this.$refs.salaryreportform.inputs.forEach((input) => {
                if (input.hasError) {
                    isValidate = false;
                }
            });
            if (!isValidate) {
                this.loading = false
                return;
            }
            // =====================================================
            if(this.processLog.length > 0){
                this.fromData['save_log'] = JSON.stringify(this.processLog);
                this.$store.dispatch("device/processLog", this.fromData).then((response) => {
                    console.log("------Console process Test-------", response);
                    if (response.data) {
                        var comp = 0;
                        var inComp = 0;
                        response.data.forEach(element => {
                            if(isObject(element)){
                                comp++;
                            }else{
                                inComp++;
                            }
                        });
                        this.fixedSuccess = "Data Successfuly Processed ("+response.data.length+") of ("+comp+") Failed ("+inComp+")";
                        this.processAlert = "Total ("+response.data.length+") of ("+comp+") Log Processed and Failed ("+inComp+")";
                        this.processLoading = false;
                    } else {
                        this.fixedError = "Data not found";
                        this.downloadLoading = false;
                    }
                }).catch(() => {
                    this.downloadLoading = false;
                })
                
            }else{
                this.fixedError = "Device log not found";
            }
        },
        isObject(obj) {	return typeof obj === 'object' && obj !== null && ! Array.isArray(obj)},

    },
    
};
</script>

<style>
    /* @import url(http://fonts.googleapis.com/icon?family=Material+Icons); */
    .leave_font {
        font-weight: bold;
    }
</style>