<template>
    <div class="container-fluid">
      <div class="container-wrappero">
        <div class="content-page-header mb-4">
          <div class="breadcrumb-header">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb content_shadow">
                <li class="breadcrumb-item">
                  <router-link to="/payments">Payment</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{
                    payment.id != ""
                      ? "Edit Payments"
                      : "Add Payments"
                  }}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <!-- content-page-header end -->
        <div class="content-body mb-4">
          <div class="add-contact">
            <div class="accordion-body">
              <v-form
                @submit="savePayment"
                v-model="valid"
                ref="paymentform"
                lazy-validation
              >
                <div class="row g-3">
                  <div class="col-md-5">
                    <label for="inputEmail4" class="form-label"
                      >Select Employee <span class="text-danger">*</span></label
                    >
                    <v-autocomplete :readonly="payment.id != ''"
                      :items="employees"
                      v-model="payment.employee_id"
                      @change="setPaymentInfo"
                      :item-text="
                        (item) => item.name + ' (' + item.employee_id + ')'
                      "
                      :item-value="(item) => item.id"
                      :rules="employeeRules"
                      outlined
                      dense
                    ></v-autocomplete>
                  </div>
                 
                  
                  <div class="col-md-7" v-if="payment_info">
                    <div class="card p-3 mb-3">
                        <div class="card-header">
                            Payment Information
                        </div>
                        <div class="row">
                            <div class="col-md-12 pt-3 padding">
                              <div class="custom-group" style="justify-content:left">
                            

                                    <label class="form-label">Employee Name: </label>
                                
                                

                                    <p class="mb-0">{{payment_info.employee.name}}</p>
                              
                              </div>
                            </div>
                            <div class="col-md-12 pt-3 padding">
                              <div class="custom-group" style="justify-content:left">
                            

                                    <label class="form-label">Department: </label>
                                    <p class="mb-0">{{payment_info.department ?payment_info.department.name : ''}}</p>
                              
                              </div>
                            </div>
                            <div class="col-md-12 pt-3 padding">
                              <div class="custom-group" style="justify-content:left">
                                    <label class="form-label">Designation: </label>
                                    <p class="mb-0">{{payment_info.designation ?payment_info.designation.name : ''}}</p>
                              
                              </div>
                            </div>
                            <div class="col-md-12 pt-3 padding">
                              <div class="custom-group" style="justify-content:left">
                                    <label class="form-label">Net Pay: </label>
                                    <p class="mb-0">{{payment_info.net_pay}}</p>
                              
                              </div>
                            </div>
                            <div class="col-md-12 pt-3 padding">
                              <div class="custom-group" style="justify-content:left">
                            

                                    <label class="form-label">Advance: </label>
                                
                                

                                    <p class="mb-0">{{payment_info.advance}}</p>
                              
                              </div>
                            </div>
                            <div class="col-md-12 pt-3 mb-2 padding">
                              <div class="custom-group" style="justify-content:left">
                            

                                    <label class="form-label">Due: </label>
                                
                                

                                    <p>{{payment_info.due}}</p>
                              
                              </div>
                            </div>
                            <input type="hidden" v-model="payment.net_pay">
                            <div class="col-md-12 padding">
                              <div class="custom-group">
                                <label class="form-label">Payment Type <span class="text-danger">*</span></label>
                                <v-select
                                    :items="payment_types"
                                    v-model="payment.type"
                                    :rules="requiredRules"
                                    :item-text="(item) => item.name"
                                    :item-value="(item) => item.value"
                                    outlined
                                    dense
                            ></v-select>
                                </div>
                            </div>
                            <div class="col-md-12 padding" v-if="payment.type == 'bank'">
                              <div class="custom-group">
                                <label class="form-label">Bank Details <span class="text-danger">*</span></label>
                                <v-textarea rows="1"
                                    :value="(payment.account ? payment.account.name : '')+', '+(payment.account ? payment.account.account_number : '')"
                                    v-model="payment.bank_details"
                                    outlined
                                    dense
                            ></v-textarea>
                                </div>
                            </div>
                            <div class="col-md-12 padding">
                              <div class="custom-group">
                                <label class="form-label">Amount <span class="text-danger">*</span></label>
                                <v-text-field
                                placeholder="Enter amount"
                                v-model="payment.amount"
                                type="number"
                                step="0.01"
                                value="0.00"
                                outlined
                                :rules="reasonRules"
                                dense
                                ></v-text-field>
                                </div>
                            </div>
                            
    
                            <div class="col-md-12 padding">
                              <div class="custom-group">
                            <label class="form-label">Remarks <span class="text-danger">*</span></label>
                                    <v-textarea
                                    placeholder="Enter comment"
                                    type="text"
                                    rows="2"
                                    v-model="payment.remarks"
                                    :rules="reasonRules"
                                    outlined
                                    dense
                                    ></v-textarea>
                                    </div>
                            </div>
                            <div class="submit-section justify-content-right">
                      
                      <div class="text-right">
                        <v-btn
                          class="custom-btn f-right"
                          color="blue darken-4"
                          type="submit"
                          :loading="loading"
                          dark
                        >
                          Submit
                        </v-btn>
                      </div>
                    </div>
                        </div>
                    </div>
                    
                  </div>
                 
                  
                </div>
                
              </v-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { de } from "vue-materialize-datatable/src/locales";
  import { mapState, mapActions } from "vuex";
  export default {
    name: "AddPayments",
  
    data: () => ({
      payment: {
        id: "",
        employee_id: "",
        bank_details: '',
        net_pay: "",
        type: "", 
        month: "",
        year: "",
        remarks: "",
        amount: "",
        status: "",
      },
      payment_info: null,
      loading: false,
      valid: true,
      error: "",
      payment_types: [
        { id: 1, name: "Bank", value: 'bank' },
        { id: 2, name: "Cash", value: 'cash' },
      ],
      requiredRules: [(v) => !!v || "Field is required"],
      monthRules: [(v) => !!v || "Month is required"],
      yearRules: [(v) => !!v || "Year is required"],
      reasonRules: [(v) => !!v || "Comment is required"],
      employeeRules: [(v) => !!v || "Employee is required"],
    }),
    async created() {
      this.getEmployees();
      if (this.$route.params.id) {
        this.getPaymentDetails();
      }
    },
    computed: {
      ...mapState("employee", ["employees"]),
    },
    methods: {
      ...mapActions("employee", ["getEmployees"]),
      savePayment(event) {
        event.preventDefault();
        this.loading = true;
        let isValidate = true;
        this.error = "";
        this.$refs.paymentform.validate();
        this.$refs.paymentform.inputs.forEach((input) => {
          if (input.hasError) {
            isValidate = false;
          }
        });
        if (!isValidate) {
          this.loading=false;
          return;
        }
        if (this.payment.id == "") {
          this.$store
            .dispatch("payment/AddPayment", this.payment)
            .then((response) => {
              this.$router.push("/payments").catch(() => {});
            })
            .catch(() => {});
        } else {
          this.$store
            .dispatch("payment/UpdatePayment", this.payment)
            .then(() => {
              this.$router.push("/payments").catch(() => {});
            })
            .catch(() => {});
        }
      },
      setPaymentInfo(){
            this.$store
            .dispatch("payment/EmployeePaymentDetails", this.payment.employee_id)
            .then((response) => {
                this.payment_info = response
                this.payment.net_pay = response.net_pay
            })
            .catch(() => {});
      },
      getPaymentDetails() {
        this.$store
          .dispatch("payment/Paymentdetails", this.$route.params.id)
          .then((res) => {
            if (res) {
              (this.payment.id = res.id),
                (this.payment.bank_details = res.bank_details),
                (this.payment.employee_id = res.employee_id),
                (this.payment.type = res.type),
                (this.payment.remarks = res.remarks),
                (this.payment.amount = res.amount)
                let info = {
                  advance: res.advance,
                  due: res.due,
                  net_pay: res.net_pay,
                  employee: res.employee,
                  department: res.employee ? res.employee.department : '',
                  designation: res.employee ? res.employee.designation : '',
                }
                this.payment_info = info
            }
          });
      },
    
      resetButton() {
        this.$refs.paymentform.reset();
      },
    },
  };
  </script>
  <style>
    .submit-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  </style>