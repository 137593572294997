import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import _ from 'lodash';
import moment from 'moment';
import CKEditor from 'ckeditor4-vue';
import DataTable from "@andresouzaabreu/vue-data-table";
import VueLoaders from 'vue-loaders';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.config.productionTip = false

// add plugin
Vue.use(VueLoaders);
Vue.use(VueSweetalert2);

Vue.use(CKEditor);
Vue.prototype.moment = moment

import Notifications from 'vue-notification'


import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
window._ = require('lodash');

Vue.use(Notifications)


window.$ = window.jQuery = require('jquery')
Vue.component("data-table", DataTable);
// // or using the defaults with no stylesheet
// Vue.use(VueHtmlToPaper);

// AXIOS
import axios from "axios";
window.axios = axios;

// axios.defaults.baseURL = "http://127.0.0.1:8000/api/v1/";
// window.baseEnv = "http://127.0.0.1:8000/";
axios.defaults.baseURL = "https://hrmback.timerni.com/api/v1/";
window.baseEnv = "https://hrmback.timerni.com/";
// axios.defaults.baseURL = "https://officeback.timerni.com/api/v1/";
// window.baseEnv = "https://officeback.timerni.com/";

const accessToken = localStorage.getItem("auth_token");
if (accessToken) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
}

Vue.config.productionTip = false
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

new Vue({
  router,
  store,
  vuetify,
  BootstrapVue,
  IconsPlugin,
  render: h => h(App)
}).$mount('#app')
