<template>
  
    <div class="container-wrappero">
        <div class="content-page-header mb-4">
            <div class="breadcrumb-header">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb content_shadow">
                        <li class="breadcrumb-item">
                            <router-link to="/dashboard">Dashboard</router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Salary Sheet </li>
                    </ol>
                </nav>
            </div>
        </div>
        <div class="content-body  mb-4">
                <v-form class="g-3" ref="salaryreportform" @submit="getSalaryReport" v-model="valid" lazy-validation>
                <div class="add-contact">
                    <div class="row">
                            <div class="col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label for="" class="mb-2"> Month
                                    </label>
                                    <v-select v-model="salary.month" :rules="monthRules" id="" :items="months" outlined flat solo dense :item-text="item=>item.name" :item-value="item=>item.id">
                                        <!-- <option value="" disabled selected>Select Month</option>
                                        <option :value="month.value"  v-for="(month, index) in months" :key="index">{{month.name}}</option> -->
                                    </v-select>
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label for="" class="mb-2">Year
                                    </label>
                                    <v-select :rules="yearRules" v-model="salary.year" id="" :items="years" outlined flat solo dense :item-text="item=>item" :item-value="item=>item">
                                        <!-- <option value="" disabled selected>Select Year</option>
                                        <option :value="year" v-for="(year, index) in years" :key="index">{{year}}</option> -->
                                    </v-select>
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-4">
                                <div class="form-group">
                                    <label for="" class="mb-2">Employee
                                    </label>
                                    <v-autocomplete :rules="employeeRules" v-model="salary.employee_id" id="" :items="finalEmployees" outlined flat solo dense :item-text="item=>item.name" :item-value="item=>item.id">
                                        
                                    </v-autocomplete>
                                </div>
                            </div>
                            <div class="col-md-2 col-lg-1">
                                <v-btn :loading="loading" type="submit" class="custom-btn btn-sm btn text-white">Submit</v-btn>
                            </div>
                        </div>
                    </div>
                </v-form>
                <div v-if="salaries.length">
                    <div class="row">
			<div class="float-right text-right">
				
                    
				<button @click="printDiv('print')" class="btn btn-sm custom-btn mb-3"><span><i class="fa fa-print"></i></span> Print</button>
				<button @click="downloadFile()" class="btn btn-sm custom-btn mb-3 ml-2"><span><i class="fa fa-print"></i></span> Export</button>
				
			</div>
		</div>
                    <div class="table-responsive card mt-4">
                        <div class="table-header" id="print">
                            <table class="material-table mt-4 table" id="print">
                                <thead>
                                    <tr class="text-center">
                                        <th>Sl</th>
                                        <th>Name</th>
                                        <th>Designation</th>
                                        <th>Joining Date</th>
                                        <th>Basic</th>
                                        <th>H. Rent</th>
                                        <th>Medical</th>
                                        <th>Incentive</th>
                                        <!-- <th>TA/DA</th> -->
                                        <th>Total</th>
                                        <th>Insurance</th>
                                        <th>P.F</th>
                                        <!-- <th>Tax</th> -->
                                        <th>Advance</th>
                                        <th>RD/WD-AD</th>
                                        <th>Fine</th>
                                        <th>Addition</th>
                                        <th>Net Pay</th>
                                        <th>Signature</th>
                                    </tr>
                                </thead>
                                <tbody v-if="salaries.length">
                                        <tr class="text-center" v-for="(salary,index) in salaries" :key="index">
                                            <td> {{index + 1}} </td>
                                            <td> {{salary.employee ? salary.employee.name : ''}}</td>
                                            <td> {{salary.employee ? salary.employee.designation ? salary.employee.designation.name : '' : ''}}</td>
                                            <td> {{ salary.employee ? salary.employee.joining_date : '' }} </td>
                                            <td> {{salary.regular_salary}} </td>
                                            <td> {{salary.house_rent}} </td>
                                            <td> {{salary.medical_allowance}} </td>
                                            <td> {{salary.incentive}} </td>
                                            <!-- <td> {{salary.tat}} </td> -->
                                            <td> {{salary.total}} </td>
                                            <td> {{salary.insurance}} </td>
                                            <td> {{salary.provident_found}} </td>
                                            <!-- <td> {{salary.tax}} </td> -->
                                            <td> {{salary.advance}} </td>
                                            <td> T-({{salary.present + salary.absent}})/P-({{salary.present}})-A({{ salary.absent }}) </td>
                                            <td> {{salary.miscellaneous_deduction + salary.fine}} </td>
                                            <td> {{salary.miscellaneous_addition}} </td>
                                            <td> {{salary.net_pay}} </td>
                                            <td>  </td>
                                        </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</template>

<script>
import exportFromJSON from "export-from-json";
import { mapActions, mapState } from "vuex";
import DataTable from "vue-materialize-datatable";

export default {
    name: 'dashboard',
    data: () => ({
        date : null,
        temp_attrs: [],
        salaries: [],
        fixedSuccess: false,
        fixedError: false,
        valid: true,
        year : null,
        loading: false,
        isValidate: false,
        month : null,
        attrs: [],
        reports: [],
        error:"",
        salary: {
            employee_id: '',
            month: '',
            year: ''
        },
        months : [
            {id: 1, value: 1, name: 'January'}, 
            {id: 2, value: 2, name: 'February'}, 
            {id: 3, value: 3, name: 'March'}, 
            {id: 4, value: 4, name: 'April'}, 
            {id: 5, value: 5, name: 'May'}, 
            {id: 6, value: 6, name: 'June'}, 
            {id: 7, value: 7, name: 'July'}, 
            {id: 8, value: 8, name: 'August'}, 
            {id: 9, value: 9, name: 'September'}, 
            {id: 10, value: 10, name: 'October'}, 
            {id: 11, value: 11, name: 'November'}, 
            {id: 12, value: 12, name: 'December'}, 
        ],
        years: [],
        monthRules: [
                (v) => !!v || "Month is required",
            ],
        yearRules: [
                (v) => !!v || "Year is required",
            ],
        employeeRules: [
                (v) => !!v || "Select Employee",
            ],

        // tableColumns1: [{
        //         label: "#",
        //         field: "sno",
        //         value: 'sno',
        //         html: false
        //     },
        //     {
        //         label: "Name",
        //         field: "name",
        //         numeric: false,
        //         html: false
        //     },
        //     {
        //         label: "Email",
        //         field: "email",
        //         numeric: false,
        //         html: false
        //     }
        // ],

        temp_month: '',
        temp_year: ''

    }),
    components: {
        "datatable": DataTable
    },

    async created() {
        // await this.getEmployeeforSalaries();
        this.getEmployees();
        this.setYear();
    },
    computed: {
        // ...mapState('employeeforsalaries', ['employeeforsalaries']),
        ...mapState('employee',['employees']),
        finalEmployees() {
            var employees = this.employees
            employees.unshift({
                    id: 'all',
                    name: 'All'
                })  
                return employees
        }
    },

    methods: {
        // ...mapActions('employeeforsalaries', ['getEmployeeforSalaries']),
        ...mapActions('employee',['getEmployees']),
        setYear(){
            var currentYear = new Date().getFullYear(),
                startYear = startYear || 2000;  
                while ( currentYear >= startYear ) {
                    this.years.push(currentYear--);
                }  
            },

            PrintCustom(){
                
            },

            downloadFile() {
                const data = this.salaries.map((salary,sl)=>{
                    return {
                        id : salary.id,
                        employee_name : salary.employee ? salary.employee.name : '',
                        designation: salary.employee ? salary.employee.designation ? salary.employee.designation.name : '' : '',
                        joining_date: salary.employee ? salary.employee.joining_date : '',
                        basic_salary: salary.regular_salary,
                        house_rent: salary.house_rent,
                        medical_allowance:salary.medical_allowance,
                        incentive: salary.incentive,
                        total: salary.total,
                        provident_fund: salary.provident_fund,
                        tax: salary.tax,
                        advance: salary.advance,
                        net_pay:salary.net_pay
                    }
                });
                const fileName = "Salary Report";
                const exportType = exportFromJSON.types.csv;

                if (data) exportFromJSON({ data, fileName, exportType });
            },

            getSalaryReport(event){
                event.preventDefault();
                this.loading = true
                let isValidate = true
                this.error = "";
                this.$refs.salaryreportform.validate();
                this.$refs.salaryreportform.inputs.forEach((input) => {
                    if (input.hasError) {
                        isValidate = false;
                    }
                });
                if (!isValidate) {
                    this.loading = false
                    return;
                }
                let month_name = this.months.find((mon)=> mon.id === this.salary.month);
                this.temp_month = month_name.name
                let year_name = this.years.find((ye)=> ye === this.salary.year);
                this.temp_year = year_name
                console.log("ami salary report generate korte aschi")
                this.$store.dispatch("salary/SalaryReport",this.salary).then((response)=>{
                    if(response.data){
                        this.loading = false
                        this.fixedSuccess = response.message
                        this.salaries = response.data;
                    }
                    else{
                        this.loading = false
                        this.fixedError = response.message
                    }
                }).catch(()=>{})
            },

            printDiv(divID) {
                var divContents = document.getElementById("print").innerHTML;
                var a = window.open('', '', '');
                a.document.write('<html><head><style type="text/css">');
                // a.document.write('<link rel="stylesheet" href="/assets/backend/css/bootstrap.min.css" type="text/css" />');
                a.document.write('body,td,th{font-family:Arial, Helvetica, sans-serif;font-size:13px;color:#000000}');
                a.document.write('.material-table td,.material-table th {border: 1px solid #e3ebf3; }');
                a.document.write('thead tr th {color: rgb(0 0 0);    font-size: 13px;    height: 45px !important;}');
                a.document.write('table{border-collapse:collapse; margin:0 auto;}');
                a.document.write('thead th{font-size:13px!important;}');
                a.document.write('table td, table tr td {    padding: 3px 2px !important;}');
                a.document.write('.bottom-line{position:fixed; transform: translateX(-50%); left:50%;bottom:0}');
                a.document.write('h3,h2,h1,p{text-align:center;}');
                a.document.write('</style></head><body> <h1>Jamalpur Central Hospital Ltd</h1><h2>Amlapara, Jamalpur</h2><h3>Salary Slip </h3>');
                a.document.write('<p>'+this.temp_month + '-'+ this.temp_year +'</p>');
                a.document.write(divContents);
                a.document.write('<p class="bottom-line"> This report is system generated, no signiture needed </p>');
                a.document.write('</body></html>');
                a.document.close();
                a.focus();
                a.print();
                // document.body.innerHTML = oldPage;
                
                location.reload(this.$router.push("/salary_slip").catch(() => {}));
            }
    },
};
</script>

<style>
    @media print {
  body * {
    visibility: hidden;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
  table {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 2px;
    border-color: grey;
    width:100%; background-color:black;
    }
    .table>thead {
    vertical-align: bottom;
}
    tbody, td, tfoot, th, thead, tr {
        border-color: inherit;
        border-style: solid;
        border-width: 0;
    }
}
    /* @import url(http://fonts.googleapis.com/icon?family=Material+Icons); */
</style>
