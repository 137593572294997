<template>
    <div class="container-fluid">
        <section class="attendance-view-wrappers">
            <div class="breadcrumb-header">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb content_shadow">
                    <li class="breadcrumb-item">
                        <router-link to="/dashboard">Dashboard</router-link>
                    </li>
                    <li class="breadcrumb-item" aria-current="page">
                        <router-link to="/attendance">View attendance</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        View
                    </li>
                    </ol>
                </nav>
            </div>
            <div class="">
                <div class="attendance calendar">
                    <div class="attendance-title">
                        <div class="caption">
                            <i class="fa fa-list"></i>
                        </div>
                    </div>
                    <div class="attendance-body text-center pt-2">
                        <div class="row m-0">
                            <div class="col-md-5 col-sm-5">
                                <form action="" class="form-info">
                                    <div class="row ">

                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label for="" class="mb-2">Select Employee
                                                </label>
                                                <select v-model="attendance.employee_id" id="" @change="getAttendancereport()" class="form-select">
                                                    <option value="" disabled selected>Select Employee</option>
                                                    <option :value="employee.id" v-for="(employee, index) in employees" :key="index">{{employee.name}}</option>
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label for="" class="mb-2"> Month
                                                </label>
                                                <select v-model="attendance.month" @change="getAttendancereport()" id="" class="form-select">
                                                    <option value="" disabled selected>Select Month</option>
                                                    <option :value="month.value"  v-for="(month, index) in months" :key="index">{{month.name}}</option>
                                                   
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label for="" class="mb-2">Year
                                                </label>
                                                <select v-model="attendance.year" @change="getAttendancereport()" id="" class="form-select">
                                                    <option value="" disabled selected>Select Year</option>
                                                    <option :value="year" v-for="(year, index) in years" :key="index">{{year}}</option>
                                                   
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="alert alert-danger text-center">
                                                <h6>Attendance </h6>
                                                <div>{{present_attendance}}/{{total_attendance}}</div>
                                            </div>

                                        </div>
                                        <div class="col-md-6">
                                            <div class="alert alert-danger text-center">
                                                <h6>Attendance %</h6>
                                                <div>{{total_attendance > 0 ? (present_attendance*100)/total_attendance : 0}}%</div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                            <div class="col-md-7 col-sm-4">
                                <template>
                                    <vc-calendar :attributes="attrs" is-expanded />
                                    <div class="attendance-dot-r"> 
                                        <div class="dots-items">
                                            <span class="dot" style="background-color: green"></span> Attend
                                        </div>
                                        <div class="dots-items">
                                            <span class="dot" style="background-color: yellow"></span> Leave
                                        </div>
                                        <div class="dots-items">
                                            <span class="dot" style="background-color: purple"></span> Holiday
                                        </div>
                                        <div class="dots-items">
                                            <span class="dot" style="background-color: red"></span> Absent
                                        </div>
                                    </div>
                                </template>
                            </div>
                            
                        </div>
                        <!-- END CALENDAR PORTLET-->
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Vue from 'vue';
import VCalendar from 'v-calendar'
import moment from 'moment';

Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />

});
export default {
    name: 'Attendance',
    
    data: () => ({
        date : null,
        temp_attrs: [],
        year : null,
        month : null,
        total_attendance: 0,
        present_attendance: 0,
        attrs: [],
        attendance: {
            employee_id: '',
            month: '',
            year: ''
        },
        months : [
            {id:1,value:1, name:'January'}, 
            {id:2,value:2, name:'February'}, 
            {id:3,value:3, name:'March'}, 
            {id:4,value:4, name:'April'}, 
            {id:5,value:5, name:'May'}, 
            {id:6,value:6, name:'June'}, 
            {id:7,value:7, name:'July'}, 
            {id:8,value:8, name:'August'}, 
            {id:9,value:9, name:'September'}, 
            {id:10,value:10, name:'October'}, 
            {id:11,value:11, name:'November'}, 
            {id:12,value:12, name:'December'}, 
        ],
        years: [],
    }),

    async created() {
        await this.getHolidays();
        this.setOccasionHoliday();
        this.setFilterAttribute();
        this.getEmployees();
        this.getEmployeeLeaves();
    },
    computed: {
        ...mapState('holiday', ['holidays']),
        ...mapState('employee',['employees']),
    },

    methods: {
        ...mapActions('holiday', ['getHolidays']),
        ...mapActions('employee',['getEmployees']),
        setOccasionHoliday()
        {
            let weekday = this.holidays
            let occasionHoliday = weekday.occasionHoliday
            let fixHoliday = weekday.fixedDay
            
           occasionHoliday?.forEach((occasion, sl)=>{
            let date = new Date(occasion.date);
            let year = date.getFullYear();
            let month = date.getMonth();
            let temp_date = date.getDate();

            let final_date = new Date(year, month, temp_date);
            let holiday_obj = {
                highlight: {
                    color: 'purple',
                    fillMode: 'solid',
                    contentClass: 'italic',
                },
                dates: final_date
            }
            this.temp_attrs.push(holiday_obj)
            })
            fixHoliday?.forEach((occasion, sl)=>{
            let date = new Date(occasion);
            let year = date.getFullYear();
            let month = date.getMonth();
            let temp_date = date.getDate();

            let final_date = new Date(year, month, temp_date);
            let holiday_obj = {
                highlight: {
                    color: 'purple',
                    fillMode: 'solid',
                    contentClass: 'italic',
                },
                dates: final_date
            }
            this.temp_attrs.push(holiday_obj)
            })
          
            this.attrs = this.temp_attrs
        },
        getEmployeeLeaves(){
            let data = {
                employee_id : this.$route.params.id
            }
            this.$store.dispatch("attendance/getEmployeeLeaves",data).then((response)=>{
                        console.log("response peyechi", response)
                        response.leaves?.forEach((occasion, sl)=>{
                        let date = new Date(occasion);
                        let year = date.getFullYear();
                        let month = date.getMonth();
                        let temp_date = date.getDate();

                        let final_date = new Date(year, month, temp_date);
                        let holiday_obj = {
                            highlight: {
                                color: 'yellow',
                                fillMode: 'solid',
                                contentClass: 'italic',
                            },
                            dates: final_date
                        }
                        this.temp_attrs.push(holiday_obj)
                        })
                    
                        this.attrs = this.temp_attrs
                    }).catch(()=>{})
        },
        getAttendancereport(){
            if(this.attendance.employee_id != '' && this.attendance.month != '' && this.attendance.year != ''){
                this.$store.dispatch("attendance/getAttendanceReport",this.attendance).then((response)=>{
                        console.log("response peyechi", response)
                        this.total_attendance = response.total_attendance
                        this.present_attendance = response.present_attendance
                    }).catch(()=>{})
            }
        },
        setFilterAttribute()
        {
            var currentYear = new Date().getFullYear(),
            startYear = startYear || 2000;
            while ( currentYear >= startYear ) {
                this.years.push(currentYear--);
            }   
        },
    },
};
</script>

<style>
    @import url(http://fonts.googleapis.com/icon?family=Material+Icons);
    .leave_font {
        font-weight: bold;
    }

    /* Page breadcrumb */

    .page-bar {
        padding: 0px;
        background-color: #f7f7f7;
        margin-bottom: 25px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        border-radius: 4px;
    }

    .page-bar:before,
    .page-bar:after {
        content: " ";
        display: table;
    }

    .page-bar:after {
        clear: both;
    }

    .page-bar .page-breadcrumb {
        display: inline-block;
        padding: 8px;
        margin: 0;
        list-style: none;
    }

    .page-bar .page-breadcrumb>li {
        display: inline-block;
    }

    .page-bar .page-breadcrumb>li>a:hover,
    .page-bar .page-breadcrumb>li>a:visited {
        text-decoration: underline;
    }

    .page-bar .page-breadcrumb>li>a,
    .page-bar .page-breadcrumb>li>span {
        color: #888;
        font-size: 14px;
        text-shadow: none;
    }

    .page-bar .page-breadcrumb>li>i {
        color: #aaa;
        font-size: 14px;
        text-shadow: none;
    }

    .page-bar .page-breadcrumb>li>i[class^="icon-"],
    .page-bar .page-breadcrumb>li>i[class*="icon-"] {
        color: gray;
    }

    .attendance {
        border: 1px solid #2ae0bb;
        border-top: 0;
    }

    .attendance>.attendance-title {
        background-color: #1BBC9B;
        padding-left: 10px;
    }

    .attendance>.attendance-title>.caption>i {
        margin-top: 4px;
        display: inline-block;
        font-size: 13px;
        margin-right: 5px;
        color: #666;
        color: #FFFFFF;
    }

    .attendance>.attendance-title>.caption {
        display: inline-block;
        font-size: 18px;
        line-height: 18px;
        font-weight: 300;
        padding: 10px 0;
        color: #fff;
        padding: 11px 0 9px 0;
    }

    .attendance .attendance-body .form-info {
        background-color: #d9edf7;
        border-color: #bce8f1;
        color: #31708f;
        border-width: 1px;
        padding: 15px;
        margin-bottom: 20px;
    }

    .attendance .attendance-body .form-info .form-select {
        font-size: 14px;
    }

    .attendance .attendance-body .form-info .form-group {
        margin-bottom: 15px;
    }

    .attendance .attendance-body hr {
        border: 0;
        border-top: 1px solid #57b9ca;
        border-bottom: 0;
        margin: 20px 0px;
    }

    .attendance h2.select {
        font-weight: 300;
        font-size: 25px;
        padding: 10px 0px;
    }
    .dot {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        display: inline-block;
    }
    .attendance-dot-r{
        display: flex;
        align-items: center;
        gap: 15px;
        justify-content: center;
        margin: 15px 0px;
    }
     .attendance-dot-r .dots-items{
        display: flex;
        align-items: center;
        gap: 5px;
    }
    .vc-day{
        min-height: 60px!important;
    }
</style>
