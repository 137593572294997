<template>
  <div class="container-fluid">
    <div class="container-wrappero">
      <div class="content-page-header mb-4">
        <div class="breadcrumb-header">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb content_shadow">
              <li class="breadcrumb-item">
                <router-link to="/employee">Employee</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Add Employee
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- content-page-header end -->
      <div class="content-body mb-4">
        <v-form
          @submit="saveEmployee"
          v-model="valid"
          ref="employeeform"
          lazy-validation
        >
          <div class="add-contact row">
            <div class="col-md-6">
              <div class="accordion accordion-main" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Personal Details
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div class="row g-3">
                        <div class="col-md-12 padding mt-0">
                          <div
                            class="
                              employee-image
                              img-thumbnail
                              text-center
                              mb-3
                            "
                          >
                            <img
                              v-if="emp_image"
                              class="img-thumbnail"
                              :src="emp_image"
                              alt=""
                            />
                          </div>
                          <div class="custom-group">
                            <label for="inputEmail4" class="form-label"
                              >Employee Photo</label
                            >
                            <v-file-input
                              accept="image/*"
                              label="File input (Max size 2MB)"
                              outlined
                              solo
                              flat
                              @change="previewImage"
                              v-model="main_image"
                              dense
                            ></v-file-input>
                          </div>
                        </div>
                        <div class="col-md-12 padding mt-2">
                          <div class="custom-group">
                            <label class="form-label"
                              >Employee Name
                              <span class="text-danger">*</span></label
                            >
                            <v-text-field
                              placeholder="Enter Employee Name"
                              type="text"
                              v-model="employee.name"
                              :rules="requiredRules"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>

                        <div class="col-md-12 padding mt-0">
                          <div class="custom-group">
                            <label class="form-label">Father's Name</label>
                            <v-text-field
                              placeholder="Father's Name"
                              type="text"
                              v-model="employee.father_name"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-12 padding mt-0">
                          <div class="custom-group">
                            <label class="form-label">Mother's Name</label>
                            <v-text-field
                              placeholder="Mother's Name"
                              type="text"
                              v-model="employee.mother_name"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-12 padding mt-0">
                          <div class="custom-group">
                            <label for="inputEmail4" class="form-label"
                              >Date of Birth
                              <span class="text-danger">*</span></label
                            >
                            <v-text-field
                              placeholder="Date of Birth"
                              type="date"
                              v-model="employee.dob"
                              :rules="requiredRules"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>

                        <div class="col-md-12 padding mt-0">
                          <div class="custom-group">
                            <label for="inputEmail4" class="form-label"
                              >Gender <span class="text-danger">*</span></label
                            >
                            <v-select
                              :items="gender_items"
                              v-model="employee.gender"
                              :rules="requiredRules"
                              :item-text="(item) => item.name"
                              :item-value="(item) => item.value"
                              outlined
                              dense
                            ></v-select>
                          </div>
                        </div>
                        <div class="col-md-12 padding mt-0">
                          <div class="custom-group">
                            <label class="form-label"
                              >Phone</label
                            >
                            <v-text-field
                              placeholder="Phone"
                              type="tel"
                              pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                              @keypress="isNumber($event)"
                              v-model="employee.phone"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-12 padding mt-0">
                          <div class="custom-group">
                            <label class="form-label"
                              >Email </label>
                            <v-text-field
                              placeholder="Email"
                              type="email"
                              v-model="employee.email"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-12 padding mt-0">
                          <div class="custom-group">
                            <label class="form-label">Local Address</label>
                            <v-text-field
                              placeholder="Local Address"
                              type="text"
                              v-model="employee.local_address"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-12 padding mt-0">
                          <div class="custom-group">
                            <label class="form-label">Permanent Address</label>
                            <v-text-field
                              placeholder="Permanent Address"
                              type="text"
                              v-model="employee.permanent_address"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-12 padding mt-0">
                          <div class="custom-group">
                            <label for="inputEmail4" class="form-label"
                              >Status <span class="text-danger">*</span></label
                            >
                            <v-select
                              :items="status_items"
                              v-model="employee.status"
                              :item-text="(item) => item.name"
                              :item-value="(item) => item.value"
                              outlined
                              dense
                            ></v-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div class="col-md-6">
              <div class="accordion accordion-main mb-3" id="accordionCompany">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingCompany">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      Company Details
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingCompany"
                    data-bs-parent="#accordionCompany"
                  >
                    <div class="accordion-body">
                      <div class="row g-3">
                        <div class="col-md-12 padding">
                          <div class="custom-group">
                            <label class="form-label"
                              >Employee Id
                              <span class="text-danger">*</span></label
                            >
                            <v-text-field
                              type="text"
                              v-model="employee.employee_id"
                              :rules="requiredRules"
                              :value="employeeId"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-12 padding mt-0">
                          <div class="custom-group">
                            <label class="form-label"
                              >Select Department
                              <span class="text-danger">*</span></label
                            >
                            <v-select
                              :items="departments"
                              :item-text="(item) => item.name"
                              :item-value="(item) => item.id"
                              v-model="employee.department_id"
                              :rules="requiredRules"
                              solo
                              flat
                              dense
                              outlined
                              label="Select Department"
                            ></v-select>
                          </div>
                        </div>
                        <div class="col-md-12 padding mt-0">
                          <div class="custom-group">
                            <label class="form-label"
                              >Select Designation
                              <span class="text-danger">*</span></label
                            >
                            <v-select
                              :items="designations"
                              v-model="employee.designation_id"
                              :item-text="(item) => item.name"
                              :item-value="(item) => item.id"
                              :rules="requiredRules"
                              dense
                              solo
                              flat
                              outlined
                              label="Select Designation"
                            ></v-select>
                          </div>
                        </div>

                        <div class="col-md-12 padding mt-0">
                          <div class="custom-group">
                            <label for="inputEmail4" class="form-label"
                              >Joining Date
                              <span class="text-danger">*</span></label
                            >
                            <v-text-field
                              placeholder="Joining Date"
                              dense
                              type="date"
                              v-model="employee.joining_date"
                              :rules="requiredRules"
                              outlined
                            ></v-text-field>
                          </div>
                        </div>

                        <div class="col-md-12 padding mt-0">
                          <div class="custom-group">
                            <label class="form-label"
                              >Joining Salary
                              <span class="text-danger">*</span></label
                            >
                            <v-text-field
                              placeholder="Joining Salary"
                              type="number"
                              v-model="employee.joining_salary"
                              :rules="requiredRules"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-12 padding mt-0">
                            <div class="custom-group">
                              <label class="form-label">Medical Allowance</label>
                              <v-text-field
                                placeholder="Medical Allowance"
                                type="number"
                                v-model="employee.medical_allowance"
                                outlined
                                dense
                              ></v-text-field>
                            </div>
                          </div>
                          <div class="col-md-12 padding mt-0">
                            <div class="custom-group">
                              <label class="form-label">Provident Found</label>
                              <v-text-field
                                placeholder="Provident Found"
                                type="number"
                                v-model="employee.provident_found"
                                outlined
                                dense
                              ></v-text-field>
                            </div>
                          </div>
                          <div class="col-md-12 padding mt-0">
                            <div class="custom-group">
                              <label class="form-label">House Rent</label>
                              <v-text-field
                                placeholder="House Rent"
                                type="number"
                                v-model="employee.house_rent"
                                outlined
                                dense
                              ></v-text-field>
                            </div>
                          </div>
                          <div class="col-md-12 padding mt-0">
                            <div class="custom-group">
                              <label class="form-label">Incentive</label>
                              <v-text-field
                                placeholder="Incentive"
                                type="number"
                                v-model="employee.incentive"
                                outlined
                                dense
                              ></v-text-field>
                            </div>
                          </div>
                          <div class="col-md-12 padding mt-0">
                            <div class="custom-group">
                              <label class="form-label">Insurance</label>
                              <v-text-field
                                placeholder="Insurance"
                                type="number"
                                v-model="employee.insurance"
                                outlined
                                dense
                              ></v-text-field>
                            </div>
                          </div>
                          <div class="col-md-12 padding mt-0">
                            <div class="custom-group">
                              <label class="form-label">TA/DA</label>
                              <v-text-field
                                placeholder="TA/DA"
                                type="number"
                                v-model="employee.tat"
                                outlined
                                dense
                              ></v-text-field>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                          
                          
            </div>
            
              
              <div class="accordion accordion-main" id="accordionAccount">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingAccount">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseAccount"
                      aria-expanded="true"
                      aria-controls="collapseAccount"
                    >
                      Bank Information Details
                    </button>
                  </h2>
                  <div
                    id="collapseAccount"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingAccount"
                    data-bs-parent="#accordionAccount"
                  >
                    <div class="accordion-body">
                      <div class="row g-3">
                        <div class="col-md-12 padding">
                          <div class="custom-group">
                            <label class="form-label"
                              >Account Holder Name</label
                            >
                            <v-text-field
                              placeholder="Holder name"
                              type="text"
                              v-model="employee.holder_name"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-12 padding mt-0">
                          <div class="custom-group">
                            <label class="form-label">Account Number</label>
                            <v-text-field
                              placeholder="Enter your account number"
                              type="text"
                              v-model="employee.account_number"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-12 padding mt-0">
                          <div class="custom-group">
                            <label class="form-label">Bank Name</label>
                            <v-text-field
                              placeholder="Enter your bank name"
                              type="text"
                              v-model="employee.bank_name"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-12 padding mt-0">
                          <div class="custom-group">
                            <label class="form-label">IFSC Code</label>
                            <v-text-field
                              placeholder="Enter ifsc code"
                              type="text"
                              v-model="employee.ifsc_code"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-12 padding mt-0">
                          <div class="custom-group">
                            <label class="form-label">PAN Number</label>
                            <v-text-field
                              placeholder="Enter pan number"
                              type="text"
                              v-model="employee.pan_number"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-12 padding mt-0">
                          <div class="custom-group">
                            <label class="form-label">Branch</label>
                            <v-text-field
                              placeholder="Enter your branch"
                              type="text"
                              v-model="employee.branch"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="submit-section">
                <div class="">
                  <router-link to="/employee">
                      <v-btn
                      class="mr-4 custom-btn"
                      type="button"
                      color="error"
                    >
                      Cancel
                    </v-btn>
                  </router-link>
                </div>
                <div class="">
                  <v-btn
                    class="custom-btn f-right"
                    color="blue darken-4"
                    type="submit"
                    :loading="loading"
                    dark
                  >
                    Submit
                  </v-btn>
                </div>
              </div>
          </div>
          </v-form>
        </div>
      </div>
    </div>
</template>
      
      <script>
  import { mapState, mapActions } from "vuex";
  export default {
    name: "AddEmployee",
  
    data: () => ({
      employee: {
        id: "",
        name: "",
        description: "",
        employee_id: "",
        department_id: "",
        designation_id: "",
        father_name: "",
        mother_name: "",
        email: "",
        image: "",
        dob: "",
        joining_date: "",
        gender: 2,
        phone: "",
        local_address: "",
        permanent_address: "",
        joining_date: "",
        bank_name: "",
        branch: "",
        account_number: "",
        holder_name: "",
        pan_number: "",
        ifsc_code: "",
        joining_salary: "",
        medical_allowance: "",
        provident_found: "",
        house_rent: "",
        incentive: "",
        insurance: "",
        tat: "",
        status: 1,
      },
      main_image: [],
      emp_image: null,
      loading: false,
      valid: true,
      error: "",
      previewImageValidation: "",
      status_items: [
        { id: 0, name: "De-active", value: 0 },
        { id: 1, name: "Active", value: 1 },
      ],
      gender_items: [
        { id: 1, name: "Female", value: 1 },
        { id: 2, name: "Male", value: 2 },
      ],
      requiredRules: [(v) => !!v || "Field is required"],
      emailRules: [
        (v) => !!v || "Email is required",
        (v) =>
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Enter valid email address",
      ],
      phoneRules: [
        (v) => !!v || "Phone is required",
        (v) => v.length >= 8 || "Phone number requires at least 8 digit",
        (v) => v.length < 12 || "Phone number cannot more than 11 digit",
      ],
    }),
    async created() {
      this.getEmployeeId();
      this.setEmployeeId();
      this.getDepartments();
      this.getDesignations();
      if (this.$route.params.id) {
        this.getEmployeeDetails();
      }
    },
    computed: {
      ...mapState("employee", ["employeeId"]),
      ...mapState("department", ["departments"]),
      ...mapState("designation", ["designations"]),
    },
    methods: {
      ...mapActions("employee", ["getEmployeeId"]),
      ...mapActions("department", ["getDepartments"]),
      ...mapActions("designation", ["getDesignations"]),
      saveEmployee(event) {
        event.preventDefault();
        this.loading = true;
        let isValidate = true;
        this.error = "";
        this.$refs.employeeform.validate();
        this.$refs.employeeform.inputs.forEach((input) => {
          if (input.hasError) {
            isValidate = false;
          }
        });
        if (!isValidate) {
          this.loading = false;
          return;
        }
        if (this.employee.id == "") {
          this.$store
            .dispatch("employee/AddEmployee", this.employee)
            .then((response) => {
              this.$router.push("/employee").catch(() => {});
            })
            .catch(() => {});
        } else {
          this.$store
            .dispatch("employee/UpdateEmployee", this.employee)
            .then((response) => {
              this.$router.push("/employee").catch(() => {});
            })
            .catch(() => {});
        }
      },
      isNumber: function (evt) {
        evt = evt ? evt : window.event;
        var charCode = evt.which ? evt.which : evt.keyCode;
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
        ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      createImage(file) {
        const reader = new FileReader();
  
        reader.onload = (e) => {
          this.emp_image = e.target.result;
          this.employee.image = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      previewImage(file) {
            var size = 2*1024*1024;
            if (!file) {
                this.emp_image = null
                return;
            }else if(file.size < size){
                this.createImage(file);
            }else{
                let upload_size = parseFloat((file.size /1024)/1024).toFixed(2);
                this.$swal({
                    title: "OOPS",
                    text: "Image must be less then 2mb! But you have uploaded "+ upload_size + "Mb",
                    type: "warning",
                    confirmButtonColor: "#3085d6",
                })
                this.main_image = null
            }
            
        },
      getEmployeeDetails() {
        this.$store
          .dispatch("employee/Employeedetails", this.$route.params.id)
          .then((res) => {
            if (res) {
              (this.employee.id = res.id),
                (this.employee.name = res.name),
                (this.employee.father_name = res.father_name),
                (this.employee.mother_name = res.mother_name),
                (this.employee.phone = res.phone),
                (this.employee.email = res.email),
                (this.employee.local_address = res.local_address),
                (this.employee.permanent_address = res.permanent_address),
                (this.employee.dob = res.dob),
                (this.emp_image = res.image),
                (this.employee.employee_id = res.employee_id),
                (this.employee.gender = res.gender),
                (this.employee.designation_id = res.designation_id),
                (this.employee.joining_date = res.joining_date),
                (this.employee.joining_salary = res.joining_salary),
                (this.employee.medical_allowance = res.medical_allowance),
                (this.employee.provident_found = res.provident_found),
                (this.employee.house_rent = res.house_rent),
                (this.employee.incentive = res.incentive),
                (this.employee.insurance = res.insurance),
                (this.employee.tat = res.tat),
                (this.employee.department_id = res.department_id),
                (this.employee.holder_name = res.bank_details.length
                  ? res.bank_details[0].holder_name
                  : ""),
                (this.employee.branch = res.bank_details.length
                  ? res.bank_details[0].branch
                  : ""),
                (this.employee.account_number = res.bank_details.length
                  ? res.bank_details[0].account_number
                  : ""),
                (this.employee.ifsc_code = res.bank_details.length
                  ? res.bank_details[0].ifsc_code
                  : ""),
                (this.employee.bank_name = res.bank_details.length
                  ? res.bank_details[0].bank_name
                  : ""),
                (this.employee.pan_number = res.bank_details.length
                  ? res.bank_details[0].pan_number
                  : ""),
                (this.employee.status = res.status);
            }
          });
      },
      setEmployeeId() {
        this.employee.employee_id = this.employeeId;
      },
      resetButton() {
        let employee_id = this.employee.employee_id
        this.$refs.employeeform.reset();
        this.employee.employee_id = employee_id
      },
    },
  };
  </script>
      <style>
  .accordion-main {
    border: 1px solid #ddd;
    margin-right: 2px;
    padding: 10px;
  }
  .add-contact .accordion-item {
    margin-bottom: 0px;
  }
  .employee-image {
    width: 200px;
    height: 200px;
    text-align: center;
    margin: 0 auto;
  }
  .employee-image img {
    height: 100%;
    object-fit: cover;
  }
  .submit-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  </style>
      
